import * as Sentry from "@sentry/gatsby";

// https://docs.sentry.io/platforms/javascript/guides/gatsby/

// see https://docs.sentry.io/platforms/javascript/guides/gatsby/configuration/options/
Sentry.init({
  dsn: "https://4cacaaa8d43347529b159d66bdf8c3b2@sentry.tech.wow.ceo/5",

  // A rate of 1 means all traces will be sent, so it's good for testing.
  // In production, you'll likely want to either choose a lower rate or use `tracesSampler` instead.
  tracesSampleRate: 0.01,

  enabled: process.env.NODE_ENV === "production"
});
